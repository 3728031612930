<template>
  <fragment>
    <div v-if="!isError">
      <p>You have been unsubscribed from the daily opportunity emails.</p>
      <p>Emails generated in the queue prior to the unsubscribe action are unable to be stopped.</p>
      <p>We appreciate your patience.</p>
    </div>

    <div v-if="isError" class="alert alert-danger">
      <p>Wrong Link</p>
      <p>
        You have clicked on an invalid link. Please make sure that you have typed the link correctly. If are copying this link from a mail reader please ensure that
        you have copied all the lines in the link.
      </p>
    </div>
  </fragment>
</template>

<script>
export default {
  name: "email-unsubscribe",
  data: function() {
    return {
      isError: false,
    };
  },
  methods: {},
  mounted() {}
};
</script>
